"use strict";

var tokenAbi = [{
  "constant": true,
  "inputs": [],
  "name": "name",
  "outputs": [{
    "name": "",
    "type": "string"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_spender",
    "type": "address"
  }, {
    "name": "_value",
    "type": "uint256"
  }],
  "name": "approve",
  "outputs": [{
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "totalSupply",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_from",
    "type": "address"
  }, {
    "name": "_to",
    "type": "address"
  }, {
    "name": "_value",
    "type": "uint256"
  }],
  "name": "transferFrom",
  "outputs": [{
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "decimals",
  "outputs": [{
    "name": "",
    "type": "uint8"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "name": "_owner",
    "type": "address"
  }],
  "name": "balanceOf",
  "outputs": [{
    "name": "balance",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "symbol",
  "outputs": [{
    "name": "",
    "type": "string"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_to",
    "type": "address"
  }, {
    "name": "_value",
    "type": "uint256"
  }],
  "name": "transfer",
  "outputs": [{
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "name": "_owner",
    "type": "address"
  }, {
    "name": "_spender",
    "type": "address"
  }],
  "name": "allowance",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "payable": true,
  "stateMutability": "payable",
  "type": "fallback"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "name": "owner",
    "type": "address"
  }, {
    "indexed": true,
    "name": "spender",
    "type": "address"
  }, {
    "indexed": false,
    "name": "value",
    "type": "uint256"
  }],
  "name": "Approval",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "name": "from",
    "type": "address"
  }, {
    "indexed": true,
    "name": "to",
    "type": "address"
  }, {
    "indexed": false,
    "name": "value",
    "type": "uint256"
  }],
  "name": "Transfer",
  "type": "event"
}];
var oracleAggregatorAbi = [{
  "inputs": [{
    "internalType": "address",
    "name": "_owner",
    "type": "address"
  }],
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }],
  "name": "getTokenOracleDecimals",
  "outputs": [{
    "internalType": "uint8",
    "name": "_tokenOracleDecimals",
    "type": "uint8"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }],
  "name": "getTokenPrice",
  "outputs": [{
    "internalType": "uint256",
    "name": "tokenPriceUnadjusted",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "isOwner",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "renounceOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "callAddress",
    "type": "address"
  }, {
    "internalType": "uint8",
    "name": "decimals",
    "type": "uint8"
  }, {
    "internalType": "bytes",
    "name": "callData",
    "type": "bytes"
  }, {
    "internalType": "bool",
    "name": "signed",
    "type": "bool"
  }],
  "name": "setTokenOracle",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}];
var transferHandlerAbi = [{
  "inputs": [{
    "internalType": "address",
    "name": "_forwarder",
    "type": "address"
  }],
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "forwarder",
    "type": "address"
  }],
  "name": "isTrustedForwarder",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }],
  "name": "transfer",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "trustedForwarder",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "versionRecipient",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}];
var feeManagerAbi = [{
  "inputs": [{
    "internalType": "address",
    "name": "_owner",
    "type": "address"
  }, {
    "internalType": "uint16",
    "name": "_bp",
    "type": "uint16"
  }],
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "user",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "token",
    "type": "address"
  }],
  "name": "getFeeMultiplier",
  "outputs": [{
    "internalType": "uint16",
    "name": "basisPoints",
    "type": "uint16"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }],
  "name": "getPriceFeedAddress",
  "outputs": [{
    "internalType": "address",
    "name": "priceFeed",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }],
  "name": "getTokenAllowed",
  "outputs": [{
    "internalType": "bool",
    "name": "allowed",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "isOwner",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }],
  "name": "removeDefaultTokenFeeMultiplier",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "user",
    "type": "address"
  }],
  "name": "removeUserTokenFeeMultiplier",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "renounceOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint16",
    "name": "_bp",
    "type": "uint16"
  }],
  "name": "setDefaultFeeMultiplier",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "uint16",
    "name": "_bp",
    "type": "uint16"
  }],
  "name": "setDefaultTokenFeeMultiplier",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "priceFeed",
    "type": "address"
  }],
  "name": "setPriceFeedAddress",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "bool",
    "name": "allowed",
    "type": "bool"
  }],
  "name": "setTokenAllowed",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "user",
    "type": "address"
  }, {
    "internalType": "uint16",
    "name": "_bp",
    "type": "uint16"
  }],
  "name": "setUserTokenFeeMultiplier",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}];
var erc20ForwarderAbi = [{
  "inputs": [{
    "internalType": "address",
    "name": "_owner",
    "type": "address"
  }],
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "uint128",
    "name": "newBaseGas",
    "type": "uint128"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "actor",
    "type": "address"
  }],
  "name": "BaseGasChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "charge",
    "type": "uint256"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "token",
    "type": "address"
  }],
  "name": "FeeCharged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "uint128",
    "name": "newGasRefund",
    "type": "uint128"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "actor",
    "type": "address"
  }],
  "name": "GasRefundChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "uint128",
    "name": "newGasTokenForwarderBaseGas",
    "type": "uint128"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "actor",
    "type": "address"
  }],
  "name": "GasTokenForwarderBaseGasChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "newOracleAggregatorAddress",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "actor",
    "type": "address"
  }],
  "name": "OracleAggregatorChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "tokenAddress",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "actor",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "uint256",
    "name": "newGas",
    "type": "uint256"
  }],
  "name": "TransferHandlerGasChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "newForwarderAddress",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "actor",
    "type": "address"
  }],
  "name": "TrustedForwarderChanged",
  "type": "event"
}, {
  "inputs": [],
  "name": "baseGas",
  "outputs": [{
    "internalType": "uint128",
    "name": "",
    "type": "uint128"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes32",
    "name": "domainSeparator",
    "type": "bytes32"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }],
  "name": "executeEIP712",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes32",
    "name": "domainSeparator",
    "type": "bytes32"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }, {
    "internalType": "uint256",
    "name": "gasTokensBurned",
    "type": "uint256"
  }],
  "name": "executeEIP712WithGasTokens",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }],
  "name": "executePersonalSign",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }, {
    "internalType": "uint256",
    "name": "gasTokensBurned",
    "type": "uint256"
  }],
  "name": "executePersonalSignWithGasTokens",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "feeManager",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "feeReceiver",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "forwarder",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "gasRefund",
  "outputs": [{
    "internalType": "uint128",
    "name": "",
    "type": "uint128"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "gasTokenForwarderBaseGas",
  "outputs": [{
    "internalType": "uint128",
    "name": "",
    "type": "uint128"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "batchId",
    "type": "uint256"
  }],
  "name": "getNonce",
  "outputs": [{
    "internalType": "uint256",
    "name": "nonce",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_feeReceiver",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "_feeManager",
    "type": "address"
  }, {
    "internalType": "address payable",
    "name": "_forwarder",
    "type": "address"
  }],
  "name": "initialize",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "isOwner",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "oracleAggregator",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes32",
    "name": "domainSeparator",
    "type": "bytes32"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }, {
    "components": [{
      "internalType": "address",
      "name": "holder",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "value",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "nonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "expiry",
      "type": "uint256"
    }, {
      "internalType": "bool",
      "name": "allowed",
      "type": "bool"
    }, {
      "internalType": "uint8",
      "name": "v",
      "type": "uint8"
    }, {
      "internalType": "bytes32",
      "name": "r",
      "type": "bytes32"
    }, {
      "internalType": "bytes32",
      "name": "s",
      "type": "bytes32"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.PermitRequest",
    "name": "permitOptions",
    "type": "tuple"
  }],
  "name": "permitAndExecuteEIP712",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes32",
    "name": "domainSeparator",
    "type": "bytes32"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }, {
    "components": [{
      "internalType": "address",
      "name": "holder",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "value",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "nonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "expiry",
      "type": "uint256"
    }, {
      "internalType": "bool",
      "name": "allowed",
      "type": "bool"
    }, {
      "internalType": "uint8",
      "name": "v",
      "type": "uint8"
    }, {
      "internalType": "bytes32",
      "name": "r",
      "type": "bytes32"
    }, {
      "internalType": "bytes32",
      "name": "s",
      "type": "bytes32"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.PermitRequest",
    "name": "permitOptions",
    "type": "tuple"
  }, {
    "internalType": "uint256",
    "name": "gasTokensBurned",
    "type": "uint256"
  }],
  "name": "permitAndExecuteEIP712WithGasTokens",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes32",
    "name": "domainSeparator",
    "type": "bytes32"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }, {
    "components": [{
      "internalType": "address",
      "name": "holder",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "value",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "nonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "expiry",
      "type": "uint256"
    }, {
      "internalType": "bool",
      "name": "allowed",
      "type": "bool"
    }, {
      "internalType": "uint8",
      "name": "v",
      "type": "uint8"
    }, {
      "internalType": "bytes32",
      "name": "r",
      "type": "bytes32"
    }, {
      "internalType": "bytes32",
      "name": "s",
      "type": "bytes32"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.PermitRequest",
    "name": "permitOptions",
    "type": "tuple"
  }],
  "name": "permitEIP2612AndExecuteEIP712",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes32",
    "name": "domainSeparator",
    "type": "bytes32"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }, {
    "components": [{
      "internalType": "address",
      "name": "holder",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "value",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "nonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "expiry",
      "type": "uint256"
    }, {
      "internalType": "bool",
      "name": "allowed",
      "type": "bool"
    }, {
      "internalType": "uint8",
      "name": "v",
      "type": "uint8"
    }, {
      "internalType": "bytes32",
      "name": "r",
      "type": "bytes32"
    }, {
      "internalType": "bytes32",
      "name": "s",
      "type": "bytes32"
    }],
    "internalType": "struct ERC20ForwardRequestTypes.PermitRequest",
    "name": "permitOptions",
    "type": "tuple"
  }, {
    "internalType": "uint256",
    "name": "gasTokensBurned",
    "type": "uint256"
  }],
  "name": "permitEIP2612AndExecuteEIP712WithGasTokens",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "renounceOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "name": "safeTransferRequired",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint128",
    "name": "gas",
    "type": "uint128"
  }],
  "name": "setBaseGas",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_feeManager",
    "type": "address"
  }],
  "name": "setFeeManager",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_feeReceiver",
    "type": "address"
  }],
  "name": "setFeeReceiver",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint128",
    "name": "refund",
    "type": "uint128"
  }],
  "name": "setGasRefund",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint128",
    "name": "gas",
    "type": "uint128"
  }],
  "name": "setGasTokenForwarderBaseGas",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "oa",
    "type": "address"
  }],
  "name": "setOracleAggregator",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "bool",
    "name": "_safeTransferRequired",
    "type": "bool"
  }],
  "name": "setSafeTransferRequired",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "token",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "_transferHandlerGas",
    "type": "uint256"
  }],
  "name": "setTransferHandlerGas",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address payable",
    "name": "_forwarder",
    "type": "address"
  }],
  "name": "setTrustedForwarder",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "name": "transferHandlerGas",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}];
var biconomyForwarderAbi = [{
  "inputs": [{
    "internalType": "address",
    "name": "_owner",
    "type": "address"
  }],
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "bytes32",
    "name": "domainSeparator",
    "type": "bytes32"
  }, {
    "indexed": false,
    "internalType": "bytes",
    "name": "domainValue",
    "type": "bytes"
  }],
  "name": "DomainRegistered",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "inputs": [],
  "name": "EIP712_DOMAIN_TYPE",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "REQUEST_TYPEHASH",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "name": "domains",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "structERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes32",
    "name": "domainSeparator",
    "type": "bytes32"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }],
  "name": "executeEIP712",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "structERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }],
  "name": "executePersonalSign",
  "outputs": [{
    "internalType": "bool",
    "name": "success",
    "type": "bool"
  }, {
    "internalType": "bytes",
    "name": "ret",
    "type": "bytes"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "batchId",
    "type": "uint256"
  }],
  "name": "getNonce",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "isOwner",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "string",
    "name": "name",
    "type": "string"
  }, {
    "internalType": "string",
    "name": "version",
    "type": "string"
  }],
  "name": "registerDomainSeparator",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "renounceOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "structERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes32",
    "name": "domainSeparator",
    "type": "bytes32"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }],
  "name": "verifyEIP712",
  "outputs": [],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "components": [{
      "internalType": "address",
      "name": "from",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {
      "internalType": "address",
      "name": "token",
      "type": "address"
    }, {
      "internalType": "uint256",
      "name": "txGas",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "tokenGasPrice",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchId",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "batchNonce",
      "type": "uint256"
    }, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {
      "internalType": "bytes",
      "name": "data",
      "type": "bytes"
    }],
    "internalType": "structERC20ForwardRequestTypes.ERC20ForwardRequest",
    "name": "req",
    "type": "tuple"
  }, {
    "internalType": "bytes",
    "name": "sig",
    "type": "bytes"
  }],
  "name": "verifyPersonalSign",
  "outputs": [],
  "stateMutability": "view",
  "type": "function"
}];
var daiAbi = [{
  "inputs": [{
    "internalType": "uint256",
    "name": "chainId_",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "src",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "guy",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "Approval",
  "type": "event"
}, {
  "anonymous": true,
  "inputs": [{
    "indexed": true,
    "internalType": "bytes4",
    "name": "sig",
    "type": "bytes4"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "usr",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "bytes32",
    "name": "arg1",
    "type": "bytes32"
  }, {
    "indexed": true,
    "internalType": "bytes32",
    "name": "arg2",
    "type": "bytes32"
  }, {
    "indexed": false,
    "internalType": "bytes",
    "name": "data",
    "type": "bytes"
  }],
  "name": "LogNote",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "src",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "dst",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "Transfer",
  "type": "event"
}, {
  "constant": true,
  "inputs": [],
  "name": "DOMAIN_SEPARATOR",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "PERMIT_TYPEHASH",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "name": "allowance",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "usr",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "approve",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "name": "balanceOf",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "usr",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "burn",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "decimals",
  "outputs": [{
    "internalType": "uint8",
    "name": "",
    "type": "uint8"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "guy",
    "type": "address"
  }],
  "name": "deny",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "usr",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "mint",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "src",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "dst",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "move",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "name",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "name": "nonces",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "holder",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "nonce",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "expiry",
    "type": "uint256"
  }, {
    "internalType": "bool",
    "name": "allowed",
    "type": "bool"
  }, {
    "internalType": "uint8",
    "name": "v",
    "type": "uint8"
  }, {
    "internalType": "bytes32",
    "name": "r",
    "type": "bytes32"
  }, {
    "internalType": "bytes32",
    "name": "s",
    "type": "bytes32"
  }],
  "name": "permit",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "usr",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "pull",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "usr",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "push",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "guy",
    "type": "address"
  }],
  "name": "rely",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "symbol",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "totalSupply",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "dst",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "transfer",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "internalType": "address",
    "name": "src",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "dst",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "wad",
    "type": "uint256"
  }],
  "name": "transferFrom",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "version",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "name": "wards",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}];
var erc20Eip2612Abi = [{
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }],
  "name": "Approval",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }],
  "name": "Transfer",
  "type": "event"
}, {
  "inputs": [],
  "name": "DOMAIN_SEPARATOR",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }],
  "name": "allowance",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }],
  "name": "approve",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "account",
    "type": "address"
  }],
  "name": "balanceOf",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }],
  "name": "nonces",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "deadline",
    "type": "uint256"
  }, {
    "internalType": "uint8",
    "name": "v",
    "type": "uint8"
  }, {
    "internalType": "bytes32",
    "name": "r",
    "type": "bytes32"
  }, {
    "internalType": "bytes32",
    "name": "s",
    "type": "bytes32"
  }],
  "name": "permit",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "totalSupply",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "recipient",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }],
  "name": "transfer",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "sender",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "recipient",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }],
  "name": "transferFrom",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}];
var usdtAbi = [{
  "constant": true,
  "inputs": [],
  "name": "name",
  "outputs": [{
    "name": "",
    "type": "string"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_upgradedAddress",
    "type": "address"
  }],
  "name": "deprecate",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_spender",
    "type": "address"
  }, {
    "name": "_value",
    "type": "uint256"
  }],
  "name": "approve",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "deprecated",
  "outputs": [{
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_evilUser",
    "type": "address"
  }],
  "name": "addBlackList",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "totalSupply",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_from",
    "type": "address"
  }, {
    "name": "_to",
    "type": "address"
  }, {
    "name": "_value",
    "type": "uint256"
  }],
  "name": "transferFrom",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "upgradedAddress",
  "outputs": [{
    "name": "",
    "type": "address"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "name": "",
    "type": "address"
  }],
  "name": "balances",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "decimals",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "maximumFee",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "_totalSupply",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [],
  "name": "unpause",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "name": "_maker",
    "type": "address"
  }],
  "name": "getBlackListStatus",
  "outputs": [{
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "name": "",
    "type": "address"
  }, {
    "name": "",
    "type": "address"
  }],
  "name": "allowed",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "paused",
  "outputs": [{
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "name": "who",
    "type": "address"
  }],
  "name": "balanceOf",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [],
  "name": "pause",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "getOwner",
  "outputs": [{
    "name": "",
    "type": "address"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "name": "",
    "type": "address"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "symbol",
  "outputs": [{
    "name": "",
    "type": "string"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_to",
    "type": "address"
  }, {
    "name": "_value",
    "type": "uint256"
  }],
  "name": "transfer",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "newBasisPoints",
    "type": "uint256"
  }, {
    "name": "newMaxFee",
    "type": "uint256"
  }],
  "name": "setParams",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "amount",
    "type": "uint256"
  }],
  "name": "issue",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "amount",
    "type": "uint256"
  }],
  "name": "redeem",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "name": "_owner",
    "type": "address"
  }, {
    "name": "_spender",
    "type": "address"
  }],
  "name": "allowance",
  "outputs": [{
    "name": "remaining",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "basisPointsRate",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{
    "name": "",
    "type": "address"
  }],
  "name": "isBlackListed",
  "outputs": [{
    "name": "",
    "type": "bool"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_clearedUser",
    "type": "address"
  }],
  "name": "removeBlackList",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "MAX_UINT",
  "outputs": [{
    "name": "",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{
    "name": "_blackListedUser",
    "type": "address"
  }],
  "name": "destroyBlackFunds",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "name": "_initialSupply",
    "type": "uint256"
  }, {
    "name": "_name",
    "type": "string"
  }, {
    "name": "_symbol",
    "type": "string"
  }, {
    "name": "_decimals",
    "type": "uint256"
  }],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "name": "amount",
    "type": "uint256"
  }],
  "name": "Issue",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "name": "amount",
    "type": "uint256"
  }],
  "name": "Redeem",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "name": "newAddress",
    "type": "address"
  }],
  "name": "Deprecate",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "name": "feeBasisPoints",
    "type": "uint256"
  }, {
    "indexed": false,
    "name": "maxFee",
    "type": "uint256"
  }],
  "name": "Params",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "name": "_blackListedUser",
    "type": "address"
  }, {
    "indexed": false,
    "name": "_balance",
    "type": "uint256"
  }],
  "name": "DestroyedBlackFunds",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "name": "_user",
    "type": "address"
  }],
  "name": "AddedBlackList",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "name": "_user",
    "type": "address"
  }],
  "name": "RemovedBlackList",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "name": "owner",
    "type": "address"
  }, {
    "indexed": true,
    "name": "spender",
    "type": "address"
  }, {
    "indexed": false,
    "name": "value",
    "type": "uint256"
  }],
  "name": "Approval",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "name": "from",
    "type": "address"
  }, {
    "indexed": true,
    "name": "to",
    "type": "address"
  }, {
    "indexed": false,
    "name": "value",
    "type": "uint256"
  }],
  "name": "Transfer",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [],
  "name": "Pause",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [],
  "name": "Unpause",
  "type": "event"
}];
var usdcAbi = [{
  "inputs": [{
    "internalType": "string",
    "name": "name",
    "type": "string"
  }, {
    "internalType": "string",
    "name": "symbol",
    "type": "string"
  }, {
    "internalType": "uint256",
    "name": "initialBalance",
    "type": "uint256"
  }],
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }],
  "name": "Approval",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "authorizer",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "bytes32",
    "name": "nonce",
    "type": "bytes32"
  }],
  "name": "AuthorizationCanceled",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "authorizer",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "bytes32",
    "name": "nonce",
    "type": "bytes32"
  }],
  "name": "AuthorizationUsed",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "_account",
    "type": "address"
  }],
  "name": "Blacklisted",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "newBlacklister",
    "type": "address"
  }],
  "name": "BlacklisterChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "burner",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }],
  "name": "Burn",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "newMasterMinter",
    "type": "address"
  }],
  "name": "MasterMinterChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "minter",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }],
  "name": "Mint",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "minter",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "minterAllowedAmount",
    "type": "uint256"
  }],
  "name": "MinterConfigured",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "oldMinter",
    "type": "address"
  }],
  "name": "MinterRemoved",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": false,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [],
  "name": "Pause",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "newAddress",
    "type": "address"
  }],
  "name": "PauserChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "newRescuer",
    "type": "address"
  }],
  "name": "RescuerChanged",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "indexed": true,
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "indexed": false,
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }],
  "name": "Transfer",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{
    "indexed": true,
    "internalType": "address",
    "name": "_account",
    "type": "address"
  }],
  "name": "UnBlacklisted",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [],
  "name": "Unpause",
  "type": "event"
}, {
  "inputs": [],
  "name": "APPROVE_WITH_AUTHORIZATION_TYPEHASH",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "CANCEL_AUTHORIZATION_TYPEHASH",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "DECREASE_ALLOWANCE_WITH_AUTHORIZATION_TYPEHASH",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "DOMAIN_SEPARATOR",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "INCREASE_ALLOWANCE_WITH_AUTHORIZATION_TYPEHASH",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "PERMIT_TYPEHASH",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "TRANSFER_WITH_AUTHORIZATION_TYPEHASH",
  "outputs": [{
    "internalType": "bytes32",
    "name": "",
    "type": "bytes32"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }],
  "name": "allowance",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }],
  "name": "approve",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "validAfter",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "validBefore",
    "type": "uint256"
  }, {
    "internalType": "bytes32",
    "name": "nonce",
    "type": "bytes32"
  }, {
    "internalType": "uint8",
    "name": "v",
    "type": "uint8"
  }, {
    "internalType": "bytes32",
    "name": "r",
    "type": "bytes32"
  }, {
    "internalType": "bytes32",
    "name": "s",
    "type": "bytes32"
  }],
  "name": "approveWithAuthorization",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "authorizer",
    "type": "address"
  }, {
    "internalType": "bytes32",
    "name": "nonce",
    "type": "bytes32"
  }],
  "name": "authorizationState",
  "outputs": [{
    "internalType": "enumGasAbstraction.AuthorizationState",
    "name": "",
    "type": "uint8"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "account",
    "type": "address"
  }],
  "name": "balanceOf",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_account",
    "type": "address"
  }],
  "name": "blacklist",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "blacklister",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "uint256",
    "name": "_amount",
    "type": "uint256"
  }],
  "name": "burn",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "authorizer",
    "type": "address"
  }, {
    "internalType": "bytes32",
    "name": "nonce",
    "type": "bytes32"
  }, {
    "internalType": "uint8",
    "name": "v",
    "type": "uint8"
  }, {
    "internalType": "bytes32",
    "name": "r",
    "type": "bytes32"
  }, {
    "internalType": "bytes32",
    "name": "s",
    "type": "bytes32"
  }],
  "name": "cancelAuthorization",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "minter",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "minterAllowedAmount",
    "type": "uint256"
  }],
  "name": "configureMinter",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "currency",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "decimals",
  "outputs": [{
    "internalType": "uint8",
    "name": "",
    "type": "uint8"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "decrement",
    "type": "uint256"
  }],
  "name": "decreaseAllowance",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "decrement",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "validAfter",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "validBefore",
    "type": "uint256"
  }, {
    "internalType": "bytes32",
    "name": "nonce",
    "type": "bytes32"
  }, {
    "internalType": "uint8",
    "name": "v",
    "type": "uint8"
  }, {
    "internalType": "bytes32",
    "name": "r",
    "type": "bytes32"
  }, {
    "internalType": "bytes32",
    "name": "s",
    "type": "bytes32"
  }],
  "name": "decreaseAllowanceWithAuthorization",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "increment",
    "type": "uint256"
  }],
  "name": "increaseAllowance",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "increment",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "validAfter",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "validBefore",
    "type": "uint256"
  }, {
    "internalType": "bytes32",
    "name": "nonce",
    "type": "bytes32"
  }, {
    "internalType": "uint8",
    "name": "v",
    "type": "uint8"
  }, {
    "internalType": "bytes32",
    "name": "r",
    "type": "bytes32"
  }, {
    "internalType": "bytes32",
    "name": "s",
    "type": "bytes32"
  }],
  "name": "increaseAllowanceWithAuthorization",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "string",
    "name": "tokenName",
    "type": "string"
  }, {
    "internalType": "string",
    "name": "tokenSymbol",
    "type": "string"
  }, {
    "internalType": "string",
    "name": "tokenCurrency",
    "type": "string"
  }, {
    "internalType": "uint8",
    "name": "tokenDecimals",
    "type": "uint8"
  }, {
    "internalType": "address",
    "name": "newMasterMinter",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "newPauser",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "newBlacklister",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "initialize",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "string",
    "name": "newName",
    "type": "string"
  }],
  "name": "initializeV2",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_account",
    "type": "address"
  }],
  "name": "isBlacklisted",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "account",
    "type": "address"
  }],
  "name": "isMinter",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "masterMinter",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "_amount",
    "type": "uint256"
  }],
  "name": "mint",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "minter",
    "type": "address"
  }],
  "name": "minterAllowance",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "name",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }],
  "name": "nonces",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "pause",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "paused",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "pauser",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "owner",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "spender",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "deadline",
    "type": "uint256"
  }, {
    "internalType": "uint8",
    "name": "v",
    "type": "uint8"
  }, {
    "internalType": "bytes32",
    "name": "r",
    "type": "bytes32"
  }, {
    "internalType": "bytes32",
    "name": "s",
    "type": "bytes32"
  }],
  "name": "permit",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "minter",
    "type": "address"
  }],
  "name": "removeMinter",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "contractIERC20",
    "name": "tokenContract",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "amount",
    "type": "uint256"
  }],
  "name": "rescueERC20",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "rescuer",
  "outputs": [{
    "internalType": "address",
    "name": "",
    "type": "address"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "symbol",
  "outputs": [{
    "internalType": "string",
    "name": "",
    "type": "string"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "totalSupply",
  "outputs": [{
    "internalType": "uint256",
    "name": "",
    "type": "uint256"
  }],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }],
  "name": "transfer",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }],
  "name": "transferFrom",
  "outputs": [{
    "internalType": "bool",
    "name": "",
    "type": "bool"
  }],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, {
    "internalType": "uint256",
    "name": "value",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "validAfter",
    "type": "uint256"
  }, {
    "internalType": "uint256",
    "name": "validBefore",
    "type": "uint256"
  }, {
    "internalType": "bytes32",
    "name": "nonce",
    "type": "bytes32"
  }, {
    "internalType": "uint8",
    "name": "v",
    "type": "uint8"
  }, {
    "internalType": "bytes32",
    "name": "r",
    "type": "bytes32"
  }, {
    "internalType": "bytes32",
    "name": "s",
    "type": "bytes32"
  }],
  "name": "transferWithAuthorization",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_account",
    "type": "address"
  }],
  "name": "unBlacklist",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "unpause",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_newBlacklister",
    "type": "address"
  }],
  "name": "updateBlacklister",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_newMasterMinter",
    "type": "address"
  }],
  "name": "updateMasterMinter",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "_newPauser",
    "type": "address"
  }],
  "name": "updatePauser",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [{
    "internalType": "address",
    "name": "newRescuer",
    "type": "address"
  }],
  "name": "updateRescuer",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}];
module.exports = {
  erc20ForwarderAbi: erc20ForwarderAbi,
  oracleAggregatorAbi: oracleAggregatorAbi,
  feeManagerAbi: feeManagerAbi,
  biconomyForwarderAbi: biconomyForwarderAbi,
  daiAbi: daiAbi,
  usdcAbi: usdcAbi,
  usdtAbi: usdtAbi,
  tokenAbi: tokenAbi,
  erc20Eip2612Abi: erc20Eip2612Abi,
  transferHandlerAbi: transferHandlerAbi
};